@tailwind base;
@tailwind components;
@tailwind utilities;

.leaflet-container {
    width: 100%;
    z-index: 1;
    height: 90.5%;
}
.auth-main-container{
    background : linear-gradient(80deg, #2A7FBA,#27CDFF);
    min-height : 100vh;
    font-family: "Montserrat", sans-serif;
}